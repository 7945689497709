import React, { Component } from 'react';
import EncouragementMessage from './EncouragementMessage.js';
import ReactTransitionGroup from 'react-addons-transition-group'
import MotivationalShouts from '../json/motivational-shouts';

class EncouragementSection extends Component {

	constructor(props) {
		super(props);
		this.giveEncouragment = this.giveEncouragment.bind(this);

    // Number of messages to show -
    this.encouragementsToShow = 2;

    // An array of the last X
    this.lastEncouragments = [];

    // All encouragementss ever displayed -
    this.state = {
        encouragements: []
    }
	}

	giveEncouragment(event) {

    this.setState((prevState, props) => {

      // Get the JSON data -
      const shouts = MotivationalShouts.motivations;
      const numShouts = shouts.length;
      // Pick a random one -
      const randomShout = shouts[Math.floor(Math.random()*numShouts)].content;
      //Copy the state -
      var encouragements = prevState.encouragements.slice();
      // Add the new one -
      encouragements.push(
        {
          content: randomShout,
          // Note its index for the key value later -
          count: encouragements.length
        }
      );

      return {encouragements};
    });
  }


	render() {

      // Grab state -
      this.lastEncouragments = this.state.encouragements;

      // trim to the last X messages -
      if(this.lastEncouragments.length>this.encouragementsToShow){
          this.lastEncouragments = this.state.encouragements.slice(this.state.encouragements.length-this.encouragementsToShow, this.state.encouragements.length);
      }

      // For each message, make an EncouragementMessage.
      // Reverse the array so the newest is at the top -
      const encouragements = [...this.lastEncouragments].map((encouragementObject, i) =>
          <EncouragementMessage content={encouragementObject.content} key={encouragementObject.count} />
      ).reverse();

    	return (
        <div className="encouragement-section">
            <button onClick={this.giveEncouragment} name="encourage">Get Encouragment</button>

            {/* Wrap them in a transition group - to inherit the componentWillEnter / componentWillLeave lifecycle events */}
            <ReactTransitionGroup>
                {encouragements}
            </ReactTransitionGroup>

            <hr/>
        </div>

    	)
  }
}

export default EncouragementSection;
