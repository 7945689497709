import React, { Component } from 'react';

class FretboardBG extends Component {
	render() {    

  	return (
      <div className="fretboard-bg">

            <div className="fretboard-bg__inner">

                <div className="fretboard-bg__nut"></div>

                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--3"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--5"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--7"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--9"></div>
                <div className="fretboard-bg__two-dot fretboard-bg__two-dot--12"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--15"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--17"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--19"></div>
                <div className="fretboard-bg__one-dot fretboard-bg__one-dot--21"></div>


            </div>

      </div>
  	)
  }
}

export default FretboardBG;
 