import React, { Component } from 'react';

class FretboardLabels extends Component {
	render() {

    const fretLabels = [];
    for (let i=0; i<=this.props.numFrets; i++) {
        fretLabels.push(<span className="fret-label" key={i}>{i}</span>);
    }


  	return (
      <div className="fretboard-labels">{fretLabels}</div>
  	)
  }
}

export default FretboardLabels;
