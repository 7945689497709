import React, { Component } from 'react';

class Key extends Component {

	constructor(props) {
		super(props);
    this.handleChange = this.handleChange.bind(this);
		this.handleChangeHover = this.handleChangeHover.bind(this);
	}

	handleChange(event) {
		this.props.onFretClicked(this.props.noteNumber%12, this.props.noteNumber);
	}

  handleChangeHover(event) {
    if(this.props.hoverModeActive && this.props.spaceIsDown){
        this.props.onFretClicked(this.props.noteNumber%12, this.props.noteNumber);  
    }  
  }

  	render() {
		
      const name = this.props.noteName;
      const number = this.props.noteNumber;
      const isSelected = this.props.noteData.isSelected;      
  		const isEbony = this.props.isEbony;  		
      const isDisabled = (!isSelected && this.props.restrictedPlayMode);

      const isLastNote = (this.props.lastNote === number % 12);
      

  		
      const keyClassList =       
      `key ` +      
      `${isEbony ? ' key--ebony' : ''}`+
      `${isSelected ? ' key--selected' : ''}`+      
      `${isDisabled ? ' is-disabled' : ''}`+      
      `${isLastNote ? ' is-current' : ''}`+
      ` key--${number%12}`;

    	return (
          
          <div className={keyClassList}
               onClick={this.handleChange}
               onMouseEnter={this.handleChangeHover}              
               data-number={number}
               data-notename={name}
               >
              <p>{name}</p>
          </div>        

    	)
    }
}

export default Key;
