import Tone from 'tone';

const MAX_NOTE_LENGTH = 0.15;

let synthInstance;

// Exported methods -
const toneOn = (noteNumber) => {

    if(!synthInstance){
        synthInstance = new Tone.PolySynth(6, Tone.Synth, {
            "oscillator" : {
            "partials" : [0, 2, 3, 4],
        }}).toMaster();

        synthInstance.set("detune", -1200);
    }

    const playNote = Tone.Midi(noteNumber).toFrequency();
    synthInstance.triggerAttackRelease(playNote, MAX_NOTE_LENGTH);
}

export {
  toneOn
}