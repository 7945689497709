import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import anime from 'animejs';

// Define the in and out animations
// Callback is important!!!
const animateIn = (el, callback) => {
  // console.log("animateIn - " + el);
    
  anime.timeline({
    targets: el,
    complete: callback,
    easing: 'easeOutExpo'
  })
  .add({
    scale: [0,1],
    opacity: [0,1],
    maxHeight: ["0px", "40px"],
    duration: 1000,    
  })    
}

const animateOut = (el, callback) => {
  // console.log("animateIn - " + el);
  
  anime.timeline({
    targets: el,
    complete: callback,
    easing: 'easeInExpo'
  })  
  .add({
    scale: 0,
    opacity: 0,
    duration: 500
  })
  .add({
    maxHeight: "0px",
    duration: 300,
    easing: 'easeOutSine'
  })
  
}

class EncouragementMessage extends Component {

  componentDidMount(){
    // console.log("componentDidMount - " + this.props.content);      
  }

  componentDidEnter() {
    // console.log("componentDidEnter - " + this.props.content);      
  }

  componentWillEnter(callback){
    // console.log("componentWillEnter - " + this.props.content);      
    animateIn(ReactDOM.findDOMNode(this.node), callback);    
  }

  componentWillLeave(callback){    
    // console.log("componentWillLeave - " + this.props.content);      
    animateOut(ReactDOM.findDOMNode(this.node), callback);    
  }  

	render() {
    // Note the ref setting below - new syntax for getting the dom element for a react component - 
    // Used with "ReactDOM.findDOMNode(this.node)" above
  	return (      
      <h3 className="encouragement-message" ref={node => this.node = node}>{this.props.content}</h3>          
  	)
  }
}

export default EncouragementMessage;
