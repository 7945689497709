import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

import App from './App';
import About from './components/About';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(

    <BrowserRouter>
		<Switch>
			<Route path="/about" component={
	          About
	        }/>
	        <Route path="" component={
	          App
	        }/>	        
        </Switch>
    </BrowserRouter>

    , document.getElementById('root')
);

registerServiceWorker();
