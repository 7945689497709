import React, { Component } from 'react';
import AString from './AString.js';
import ResetButton from './ResetButton.js';
import FretboardLabels from './FretboardLabels.js';
import FretboardBG from './FretboardBG.js';

class Fretboard extends Component {

	constructor(props) {
		super(props);
		this.makeString = this.makeString.bind(this);

    // The number of frets
    // This does not include 0 - ie. an open string.
    this.NUM_FRETS = 21;

    // Offsets for midi note numbers by string - 
    this.STRING_OFFSETS = [52,52,64,64,64,76];

	}

	makeString(makeStringIndex) {
		return (
			<AString
			stringIndex={makeStringIndex}
			tuning={this.props.tuning}
			noteStatus={this.props.noteStatus}
			onFretClicked={this.props.onFretClicked}
			onTuningPegUpdated={this.props.onTuningPegUpdated}
      numFrets = {this.NUM_FRETS}
      stringNoteOffset = {this.STRING_OFFSETS[makeStringIndex-1]}
      restrictedPlayMode = {this.props.restrictedPlayMode}
      lastNote={this.props.lastNote}
      hoverModeActive={this.props.hoverModeActive}
      spaceIsDown={this.props.spaceIsDown}      
			/>
		)
	}

	render() {

  	return (
    <div className="fretboard-seciton">
    		<div className="fretboard">

          <FretboardLabels numFrets = {this.NUM_FRETS}/>

					<FretboardBG />					

    			{this.makeString(6)}
    			{this.makeString(5)}
    			{this.makeString(4)}
    			{this.makeString(3)}
    			{this.makeString(2)}
    			{this.makeString(1)}

          <FretboardLabels numFrets = {this.NUM_FRETS}/>

    		</div>

        <ResetButton
            label = "Reset all"
            onResetClicked={this.props.onResetClicked}
        />

				<ResetButton
            label = "Reset scale selected"
            onResetClicked={this.props.onResetScaleClicked}
        />
				
        <hr/>
    </div>
  	)
  }
}

export default Fretboard;
