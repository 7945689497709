import React, { Component } from 'react';
import {getNoteNameByNumber} from '../utilities.js';
import {Tooltip} from 'react-tippy';

import 'react-tippy/dist/tippy.css';

class ScalesButton extends Component {

  constructor(props) {
    super(props);
    this.selectThisScale = this.selectThisScale.bind(this);
  }

  selectThisScale(event) {
    this.props.clickHandler(this.props.title, this.props.notes);
  }

	render() {

    // reduce where selected is true -
    const playedNotes = this.props.noteStatus.filter(note => note.isSelected);
    const allPlayedNotesInThisScale = playedNotes.every((note) => this.props.notes.includes(note.number));
    const scaleClassList = `scales-button ${allPlayedNotesInThisScale ? 'is-active' : ''}`;

    const noteOutput = [...this.props.notes].map((note, i) => <span key={i}> {getNoteNameByNumber(note)} </span>);

  	return (

      <Tooltip
          html={noteOutput}
          theme= "scaley-boi"
          touchHold="true"
          position="top"
          distance="10"
          arrow="true"
          trigger="mouseenter"
          delay="100"
        >

          <button className={scaleClassList} onClick={this.selectThisScale}  name="scale">
              {this.props.title}
          </button>

        </Tooltip>
  	)
  }
}

export default ScalesButton;
