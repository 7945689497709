import React, { Component } from 'react';
import TuningButton from './TuningButton.js';
import TuningData from '../json/tunings';

class TuningsSection extends Component {

	render() {

      const tuningElements = [...TuningData.tunings].map((tuningObject, i) =>
          <TuningButton
              tuningName={tuningObject.title}
              tuning={tuningObject.notes}
              key={i}
              onPresetTuningClicked={this.props.onPresetTuningClicked}
              isActive={ this.props.currentTuning.toString() === tuningObject.notes.toString() }              
          />
      );


    	return (

        <div className="tunings-section">

            <h4>Tunings</h4>

            <div className="tunings">
                {tuningElements}
            </div>

            <hr/>
        </div>

    	)
  }
}

export default TuningsSection;
