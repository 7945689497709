import React, { Component } from 'react';
import PlaybackButton from './PlaybackButton.js';
import WebMidi from 'webmidi';
import WebMidiManager from '../webmidiManager.js';
import MidiSelect from './MidiSelect.js';

class PlaybackSection extends Component {

constructor(props) {
    super(props);

    this.state = {
        midiInputs:[],
        midiOutputs:[]        
    };    

    this.outputSourceClick = this.outputSourceClick.bind(this);
    this.playbackClick = this.playbackClick.bind(this);
    this.onPlaybackHover = this.onPlaybackHover.bind(this);
    this.onMidiSelectUpdated = this.onMidiSelectUpdated.bind(this);
}


componentDidMount() {
    const self = this;        

    WebMidi.enable(function (err) {        
            
        self.props.updateMidiDevcies(
            WebMidi.inputs,
            WebMidi.outputs            
        )

        WebMidiManager.setupInputs(self.props.midiInputItem);
        WebMidiManager.setupOutputs(self.props.midiOutputItem);
        WebMidiManager.setChannel(self.props.midiChannelItem);            
	});
}

  onMidiSelectUpdated(type, val){
    //   console.log(`onMidiSelectUpdated. Type - ${type}, val - ${val}`);

      // Pass the function up to store in state - 
      this.props.updateMidiChoice(type, val);

      if(type==="MIDIIN"){            
            WebMidiManager.setupInputs(val)
      }
      else if(type==="MIDIOUT")
            WebMidiManager.setupOutputs(val)
      else{
            WebMidiManager.setChannel(val)
      }

  }

  outputSourceClick(trigger) {
      this.props.onOutputSourceSelected(trigger);
  }

  playbackClick(trigger) {
      this.props.onPlaybackSelected(trigger);
  }

  onPlaybackHover(val){
      this.props.onPlaybackHover(val);  
  }


	render() {        

    	return (
        <div className="playback-section">

            <div className="playback-section__container">

                    <h4>Playback</h4>

                    <PlaybackButton
                        title={"Browser"}
                        desc={"Default behaviour - play webaudio"}
                        clickHandler={this.outputSourceClick}
                        trigger={"BROWSER"}
                        outputSourceMode={this.props.currentOutputSource}
                    />

                    <PlaybackButton
                        title={"Off"}
                        desc={"No audio"}
                        clickHandler={this.outputSourceClick}
                        trigger={"OFF"}
                        outputSourceMode={this.props.currentOutputSource}
                    />

                    <PlaybackButton
                        title={"Midi"}
                        desc={"Output to external midi channel"}
                        clickHandler={this.outputSourceClick}
                        trigger={"MIDI"}
                        outputSourceMode={this.props.currentOutputSource}
                    />                    
            </div>

            <div className="playback-section__container">

                    <h4>Playback style</h4>

                    <PlaybackButton
                        title={"Toggle"}
                        desc={"Default behaviour - toggle the notes on / off"}
                        clickHandler={this.playbackClick}
                        trigger={"TOGGLE"}
                        playbackMode={this.props.currentPlaybackMode}
                    />

                    <PlaybackButton
                        title={"Playback"}
                        desc={"Play the notes - does not toggle the state"}
                        clickHandler={this.playbackClick}
                        trigger={"PLAYBACK"}
                        playbackMode={this.props.currentPlaybackMode}
                    />

                    <PlaybackButton
                        title={"Playback - constrained"}
                        desc={"Play the notes if they are selected - does not toggle the state"}
                        clickHandler={this.playbackClick}
                        trigger={"PLAYBACK--CONSTRAINED"}
                        playbackMode={this.props.currentPlaybackMode}
                    />
            </div>

            <div className="playback-section__container">

                    <h4>Additional Playback options</h4>

                    <PlaybackButton
                        title={"On hover / space"}
                        desc={"Trigger notes on hover as well as on click when space is held"}
                        clickHandler={this.onPlaybackHover}
                        trigger={true}
                        playbackMode={this.props.hoverModeActive}
                    />

            </div>
            
            <div className="playback-section__container">

                <h4>Midi In</h4>
                <MidiSelect 
                    data = {this.props.midiInputs}                    
                    midiType = "MIDIIN"
                    onMidiSelectUpdated = {this.onMidiSelectUpdated}
                    default = {this.props.midiInputItem}
                />

                <h4>Midi Out</h4>
                <div className="midi-group">
                    <MidiSelect  
                        data = {this.props.midiOutputs}                     
                        midiType = "MIDIOUT"
                        modifiers = "midi-select--out-main"
                        onMidiSelectUpdated = {this.onMidiSelectUpdated}
                        default = {this.props.midiOutputItem}
                    />                
                    <MidiSelect  
                        data = {[{name:1},{name:2},{name:3},{name:4},{name:5},{name:6},{name:7},{name:8},{name:9},{name:10},{name:11},{name:12},{name:13},{name:14},{name:15},{name:16}]}
                        midiType = "MIDIOUTCHANNEL"
                        modifiers = "midi-select--out-min"
                        onMidiSelectUpdated = {this.onMidiSelectUpdated}
                        default = {this.props.midiChannelItem}                
                    />
                </div>
            </div>

        </div>

    	)
  }
}

export default PlaybackSection;
