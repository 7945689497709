import React, { Component } from 'react';
import TuningPeg from './TuningPeg.js';
import Fret from './Fret.js';

import {getNoteNumberByName} from '../utilities.js';

class AString extends Component {

	constructor(props) {
		super(props);

    this.makeFret = this.makeFret.bind(this);

    this.stringIndex = this.props.stringIndex;
    this.stringID = "string"+this.stringIndex;
    this.fretID = "string"+(this.stringIndex)+"Fret";

    this.pegID = "tuningPeg"+this.stringIndex;

	}

  makeFret(makeFretIndex) {

    const rootNote = this.props.tuning[this.stringIndex-1];
    const rootNoteNumber = getNoteNumberByName(rootNote);
    const thisFretData = this.props.noteStatus[(rootNoteNumber + makeFretIndex)%12];
    const thisFretNoteNumber = this.props.stringNoteOffset + rootNoteNumber + makeFretIndex;

    return (
      <Fret
          key={this.fretID + makeFretIndex}
          fretData={thisFretData}
          noteNumber={thisFretNoteNumber}
          onFretClicked={this.props.onFretClicked}
          restrictedPlayMode = {this.props.restrictedPlayMode}
          lastNote={this.props.lastNote}
          hoverModeActive={this.props.hoverModeActive}
          spaceIsDown={this.props.spaceIsDown}
      />
    )
  }

  render() {

      const frets = [];
      for (let i=0; i<=this.props.numFrets; i++) {
          frets.push(this.makeFret(i));
      }

    	return (
			<div className="string" key={this.stringID}>

					<TuningPeg
              note={this.props.tuning[this.stringIndex-1]}
              key={this.pegID}
              stringIndex={this.props.stringIndex}
              onTuningPegUpdated={this.props.onTuningPegUpdated}
            />

                <div className="fret-wrap">
                    {frets}
                </div>
			</div>
    	)
    }
}

export default AString;
