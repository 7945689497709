import React, { Component } from 'react';
import Key from './Key.js';

import {getNoteNameByNumber} from '../utilities.js';

class Keyboard extends Component {

	constructor(props) {
		super(props);

    this.makeKey = this.makeKey.bind(this);    
    
    this.NUM_KEYS = 24; // 12 notes in an octave
    // Ebony / ivory sequence -
    this.PATTERN = [false,true,false,true,false,false,true,false,true,false,true];
	}

  makeKey(makeKeyIndex) {    
    
    const isEbony = this.PATTERN[makeKeyIndex%12];
    const noteName = getNoteNameByNumber(makeKeyIndex%12);

    const noteData = this.props.noteStatus[makeKeyIndex%12];

    return (
          <Key              
              noteName={noteName}
              noteNumber={makeKeyIndex+ 60 }// Middle C offset
              noteData={noteData}
              isEbony={isEbony}
              onFretClicked={this.props.onFretClicked}
              noteStatus={this.props.noteStatus}
              key={makeKeyIndex}
              restrictedPlayMode = {this.props.restrictedPlayMode}
              lastNote={this.props.lastNote}
              hoverModeActive={this.props.hoverModeActive}
              spaceIsDown={this.props.spaceIsDown}
          />          
    )
  }

  render() {      

      const keys = [];
      for(var i=0;i<this.NUM_KEYS;i++){
          keys.push(this.makeKey(i));
      }          

    	return (

			<div className="keyboard">
          {keys}
			</div>
    	)
    }
}

export default Keyboard;
 