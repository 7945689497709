import React, { Component } from 'react';

class TuningPeg extends Component {	

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);		
	}

	handleChange(event) {
		this.props.onTuningPegUpdated(this.props.stringIndex, event.target.value, event);
	}	

  	render() {  		

  		const note = this.props.note;

    	return (
			<div className="tuningPeg">

				<select value={note} onChange={this.handleChange}>					
					<option value="C">C</option>
					<option value="C#">C#</option>
					<option value="D">D</option>
					<option value="D#">D#</option>
					<option value="E">E</option>
					<option value="F">F</option>
					<option value="F#">F#</option>
					<option value="G">G</option>
					<option value="G#">G#</option>
					<option value="A">A</option>
					<option value="A#">A#</option>
					<option value="B">B</option>
				</select>				

			</div>
    	)
    }
}

export default TuningPeg;
