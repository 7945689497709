import React, { Component } from "react";
import { withRouter } from "react-router";

import Header from "./components/Header.js";
import Fretboard from "./components/Fretboard.js";
import Keyboard from "./components/Keyboard.js";
import ScalesSection from "./components/ScalesSection.js";
import PlaybackSection from "./components/PlaybackSection.js";
import TuningsSection from "./components/TuningsSection.js";
import EncouragementSection from "./components/EncouragementSection.js";

import { toneOn } from "./audioOut.js";
import WebMidiManager from "./webmidiManager.js";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.onFretClicked = this.onFretClicked.bind(this);
    this.onResetClicked = this.onResetClicked.bind(this);
    this.onResetScaleClicked = this.onResetScaleClicked.bind(this);
    this.onTuningPegUpdated = this.onTuningPegUpdated.bind(this);
    this.onPresetTuningClicked = this.onPresetTuningClicked.bind(this);
    this.onPlaybackSelected = this.onPlaybackSelected.bind(this);
    this.onPlaybackHover = this.onPlaybackHover.bind(this);
    this.onOutputSourceSelected = this.onOutputSourceSelected.bind(this);
    this.toggleFret = this.toggleFret.bind(this);
    this.onScaleSelected = this.onScaleSelected.bind(this);
    this.dispatchANoteNoise = this.dispatchANoteNoise.bind(this);
    this.saveStateToLocalStorage = this.saveStateToLocalStorage.bind(this);
    this.onMidiNotePlayed = this.onMidiNotePlayed.bind(this);
    this.updateMidiDevcies = this.updateMidiDevcies.bind(this);
    this.updateMidiChoice = this.updateMidiChoice.bind(this);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    this.LOCAL_STORAGE_REF = "scales-app-state";
    this.noteNames = [
      "C",
      "C#",
      "D",
      "D#",
      "E",
      "F",
      "F#",
      "G",
      "G#",
      "A",
      "A#",
      "B"
    ];
    // TEMP - DUPLICATED FROM FRETBOARD - MOVE TO APP AND PASS AROUND?
    // this.STRING_OFFSETS = [0,0,12,12,12,24];
    this.STRING_OFFSETS = [52, 52, 64, 64, 64, 76];

    let initialNoteStatus = [];
    for (let i = 0; i < 12; i++) {
      initialNoteStatus.push({
        number: i,
        name: this.noteNames[i],
        isSelected: false
      });
    }

    this.state = {
      tuning: ["E", "A", "D", "G", "B", "E"],
      noteStatus: initialNoteStatus,
      playbackType: "TOGGLE",
      hoverModeActive: false,
      spaceIsDown: false,
      outputSource: "BROWSER",
      lastNote: "",

      midiInputs: [],
      midiOutputs: [],
      midiInputItem: 0,
      midiOutputItem: 0,
      midiChannelItem: 1
    };
  }

  componentWillMount() {
    const localStorageRef = localStorage.getItem(this.LOCAL_STORAGE_REF);
    let localStorageState = [];

    // If we have a svaed state - use that -
    if (localStorageRef) {
      localStorageState = JSON.parse(localStorageRef);

      localStorageState.lastNote = "";

      // Functional set state -
      this.setState(function(state) {
        return localStorageState;
      });
    }

    // The spaceIsDown should be reset even if it was saved as true -
    let spaceIsDown = false;
    this.setState({ spaceIsDown });


    if(navigator.requestMIDIAccess){
      WebMidiManager.setEvents({
        onNoteEvent: this.onMidiNotePlayed
      });
    }

  }

  componentDidUpdate() {
    // console.log("something changed - saved the state!");
    this.saveStateToLocalStorage();
  }

  updateMidiDevcies(inputs, outputs) {
    let midiInputs = inputs;
    let midiOutputs = outputs;
    this.setState({
      midiInputs: midiInputs,
      midiOutputs: midiOutputs
    });
  }

  updateMidiChoice(type, name) {
    // console.log(`updateMidiChoice - type ${type}, name - ${name}`);

    if (type === "MIDIIN") {
      this.setState({
        midiInputItem: name
      });
    } else if (type === "MIDIOUT") {
      this.setState({
        midiOutputItem: name
      });
    } else {
      this.setState({
        midiChannelItem: name
      });
    }
  }

  onKeyUp(e) {
    if (e.which === 32) {
      let spaceIsDown = false;
      this.setState({ spaceIsDown });

      e.preventDefault();
    }
  }

  onKeyDown(e) {
    if (e.which === 32) {
      if (!this.state.spaceIsDown) {
        let spaceIsDown = true;
        this.setState({ spaceIsDown });
      }

      e.preventDefault();
    }
  }

  onMidiNotePlayed(noteNum, playNoteNum) {
    // console.log("HIGHLIGHT NOTE ON MIDI PRESS - " + playNoteNum);

    // set last note in state as this one for highlight
    let lastNote = noteNum;
    this.setState({ lastNote });

    this.onFretClicked(noteNum, playNoteNum);
  }

  onFretClicked(noteNum, playNoteNum) {
    // console.log("recieved click on fret note - " + noteNum);

    // Toggle the fret
    // Only play a note if it's being turned on -
    if (this.state.playbackType === "TOGGLE") {
      this.toggleFret(noteNum, playNoteNum);
    }

    // In playback mode
    // Always play a note!
    if (this.state.playbackType === "PLAYBACK") {
      this.dispatchANoteNoise(playNoteNum);
    }

    // In restrained playback mode
    // Only play a note if it's selected.
    else if (this.state.noteStatus[noteNum].isSelected) {
      this.dispatchANoteNoise(playNoteNum);
    }
  }

  dispatchANoteNoise(playNoteNum) {
    if (this.state.outputSource === "OFF") {
      return;
    } else if (this.state.outputSource === "BROWSER") {
      // Play using web audio -
      toneOn(playNoteNum);
    } else {
      // Play using midi output -
      WebMidiManager.midiNoteOn(playNoteNum);
    }
  }

  toggleFret(noteNum, playNoteNum) {
    this.setState((prevState, props) => {
      //Copy the state -
      let noteStatus = prevState.noteStatus.slice();

      let isSelected = noteStatus[noteNum].isSelected;

      // console.log("isSelected = "+isSelected);

      if (!isSelected) {
        this.dispatchANoteNoise(playNoteNum);
        // Toggle the selected state for all instances of the note -
        noteStatus[noteNum].isSelected = true;
      } else {
        noteStatus[noteNum].isSelected = false;
      }

      return { noteStatus };
    });
  }

  // Reset all -
  onResetClicked() {
    this.setState((prevState, props) => {
      let noteStatus = prevState.noteStatus.slice();
      // Deselect all notes -
      noteStatus.map(note => (note.isSelected = false));
      return { noteStatus };
    });

    let lastNote = "";
    this.setState({ lastNote });
  }

  // Deselect notes added in by the scale selection
  onResetScaleClicked() {
    this.setState((prevState, props) => {
      let noteStatus = prevState.noteStatus.slice();

      noteStatus.forEach(function(note) {
        if (note.isSelected === "SCALE_SELECTED") {
          note.isSelected = false;
        }
      });

      return { noteStatus };
    });

    let lastNote = "";
    this.setState({ lastNote });
  }

  onTuningPegUpdated(stringIndex, selectedTuning, event) {
    // console.log("recieved update from tuning peg!");

    this.setState((prevState, props) => {
      //Copy the state -
      let tuning = prevState.tuning.slice();
      tuning[stringIndex - 1] = selectedTuning;
      return { tuning };
    });
  }

  onPresetTuningClicked(newTuning) {
    //Set the state -
    let tuning = [...newTuning];

    // No updates to current state - so standard set state should be OK -
    this.setState({ tuning });
  }

  onPlaybackSelected(playbackSelected) {
    // console.log("playbackSelected = "+playbackSelected);

    let playbackType = playbackSelected;
    this.setState({ playbackType });
  }

  onPlaybackHover() {
    // console.log("onPlaybackHover!");

    this.setState((prevState, props) => {
      let hoverModeActive = !prevState.hoverModeActive;
      return { hoverModeActive };
    });
  }

  onOutputSourceSelected(outputSourceSelected) {
    // console.log("outputSourceSelected = "+outputSourceSelected);

    let outputSource = outputSourceSelected;
    this.setState({ outputSource });
  }

  onScaleSelected(scaleData) {
    this.setState((prevState, props) => {
      //Copy the state -
      let noteStatus = prevState.noteStatus.slice();

      // console.log("noteStatus");
      // console.log(noteStatus);

      // Deselect current scale selected ones  -
      noteStatus.forEach(function(note) {
        if (note.isSelected === "SCALE_SELECTED") {
          note.isSelected = false;
        }
      });

      // Select the scale note ones -
      scaleData.forEach(function(index) {
        if (!noteStatus[index].isSelected) {
          noteStatus[index].isSelected = "SCALE_SELECTED";
        }
      });

      // this.ScalesArray(scaleData);

      return { noteStatus };
    });
  }

  saveStateToLocalStorage() {
    localStorage.setItem(this.LOCAL_STORAGE_REF, JSON.stringify(this.state));
  }

  render() {
    return (
      <div className="App" onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}>
        <Header tagline=" a guitar helper." />

        <TuningsSection
          currentTuning={this.state.tuning}
          onPresetTuningClicked={this.onPresetTuningClicked}
        />

        <Fretboard
          tuning={this.state.tuning}
          noteStatus={this.state.noteStatus}
          onFretClicked={this.onFretClicked}
          onTuningPegUpdated={this.onTuningPegUpdated}
          onResetClicked={this.onResetClicked}
          onResetScaleClicked={this.onResetScaleClicked}
          lastNote={this.state.lastNote}
          restrictedPlayMode={
            this.state.playbackType === "PLAYBACK--CONSTRAINED"
          }
          hoverModeActive={this.state.hoverModeActive}
          spaceIsDown={this.state.spaceIsDown}
        />

        <PlaybackSection
          onPlaybackSelected={this.onPlaybackSelected}
          onPlaybackHover={this.onPlaybackHover}
          onOutputSourceSelected={this.onOutputSourceSelected}
          currentPlaybackMode={this.state.playbackType}
          hoverModeActive={this.state.hoverModeActive}
          currentOutputSource={this.state.outputSource}
          midiInputs={this.state.midiInputs}
          midiOutputs={this.state.midiOutputs}
          updateMidiDevcies={this.updateMidiDevcies}
          midiInputItem={this.state.midiInputItem}
          midiOutputItem={this.state.midiOutputItem}
          midiChannelItem={this.state.midiChannelItem}
          updateMidiChoice={this.updateMidiChoice}
        />

        <ScalesSection
          noteStatus={this.state.noteStatus}
          onScaleSelected={this.onScaleSelected}
        />

        <EncouragementSection />

        <Keyboard
          noteStatus={this.state.noteStatus}
          onFretClicked={this.onFretClicked}
          lastNote={this.state.lastNote}
          restrictedPlayMode={
            this.state.playbackType === "PLAYBACK--CONSTRAINED"
          }
          hoverModeActive={this.state.hoverModeActive}
          spaceIsDown={this.state.spaceIsDown}
        />
      </div>
    );
  }
}

export default withRouter(App);
