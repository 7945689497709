
const getNoteNumberByName = (noteName) => {

  switch (noteName) {
      case 'C': return 0;
      case 'C#': return 1;
      case 'D': return 2;
      case 'D#': return 3;
      case 'E': return 4;
      case 'F': return 5;
      case 'F#': return 6;
      case 'G': return 7;
      case 'G#': return 8;
      case 'A': return 9;
      case 'A#': return 10;
      case 'B': return 11;

      default: return 0;
    }
}

const getNoteNameByNumber = (noteNumber) => {

  switch (noteNumber) {
      case 0: return 'C';
      case 1: return 'C#';
      case 2: return 'D';
      case 3: return 'D#';
      case 4: return 'E';
      case 5: return 'F';
      case 6: return 'F#';
      case 7: return 'G';
      case 8: return 'G#';
      case 9: return 'A';
      case 10: return 'A#';
      case 11: return 'B';

      default: return 0;
    }
}

export {
  getNoteNumberByName,
  getNoteNameByNumber
}