import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <div className="about">
        <h1>ABOUT</h1>

        <p>
          <strong>Scales </strong>is a web app designed to help visualise and
          experiment with music scales across a variety of tunings and
          instruments.
        </p>

        <p>
          <strong>Scales </strong>uses -{" "}
        </p>
        <ul>
          <li>React</li>
          <li>React Router</li>
          <li>WebAudio</li>
          <li>WebMidi input &amp; output</li>
          <li>Anime JS</li>
          <li>LocalStorage API</li>
          <li>Sass</li>
          <li>Netlify continous deployments</li>
        </ul>

        <p>
          Made by{" "}
          <a
            href="https://twitter.com/lawriecape"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lawrie
          </a>
          .
        </p>

        <h6>
          <Link to="/">back</Link>
        </h6>
      </div>
    );
  }
}

export default About;
