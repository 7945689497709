import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';

import 'react-tippy/dist/tippy.css';

class TuningButton extends Component {

	constructor(props) {
		super(props);
		this.setTuning = this.setTuning.bind(this);		
	}

	setTuning(event) {	
		this.props.onPresetTuningClicked(this.props.tuning);
	}

	render() {
		const buttonClass = `tuning-button${(this.props.isActive)?" is-active":""}`;

		const formattedTuning = [...this.props.tuning].toString().split(",").join(" ");

  	return (

  		<Tooltip
          html={formattedTuning}
		  theme= "scaley-boi"
		  touchHold="true" 
          position="top"
          distance="10"
          arrow="true"
          trigger="mouseenter"
          delay="100"
        >

      		<button onClick={this.setTuning} className={buttonClass}>{this.props.tuningName}</button>

	    </Tooltip>
  	)
  }
}

export default TuningButton;
