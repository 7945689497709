import React, { Component } from 'react';
import ScalesButton from './ScalesButton.js';

import {generateScales} from '../scalesGenerator.js';

class ScalesSection extends Component {

	constructor(props) {
		super(props);

    this.scalesSectionClick = this.scalesSectionClick.bind(this);
		this.scaleClick = this.scaleClick.bind(this);

    this.scalesData = generateScales();
    window.sd = this.scalesData;
	}

	scalesSectionClick(event) {
    //   console.log("scalesSectionClick!");
  }

  scaleClick(name, notes) {
    //   console.log("Clicked a scale!");
    //   console.log("Name - " + name);
    //   console.log("Notes - " + notes);
      this.props.onScaleSelected(notes);
  }

	render() {

      const scalesElements = [...this.scalesData].map((scaleObject, i) =>
          <ScalesButton
              title={scaleObject.name}
              notes={scaleObject.notes}
              key={i}
              clickHandler={this.scaleClick}
              noteStatus={this.props.noteStatus}
          />
      );

    	return (
        <div className="scales-section">

            <h4>Scales</h4>

            <div className="scales">
                {scalesElements}
            </div>

            <hr/>
        </div>

    	)
  }
}

export default ScalesSection;
