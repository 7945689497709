import React, { Component } from 'react';

class MidiSelect extends Component {	

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.generateOptions = this.generateOptions.bind(this);
	}

	handleChange(event) {
		this.props.onMidiSelectUpdated(this.props.midiType, event.target.value);
	}	

	generateOptions(){		
		
		return this.props.data.map((item, index)=>{				
			return <option val={item.name} key={`midi-${this.props.midiType}-option-${index}`}>{item.name}</option>
		});
	}

  	render() {  		  		
		let classList = `midi-select ` + this.props.modifiers;

    	return (
			<div className={classList} >
				<select  value={this.props.default} onChange={this.handleChange}>					
					{this.generateOptions()}
				</select>				

			</div>
    	)
    }
}

export default MidiSelect;
