import React, { Component } from 'react';
import {getNoteNameByNumber} from '../utilities.js';
import {Tooltip} from 'react-tippy';

import 'react-tippy/dist/tippy.css';

class Fret extends Component {

	constructor(props) {
		super(props);
    this.handleChange = this.handleChange.bind(this);
		this.handleChangeHover = this.handleChangeHover.bind(this);
	}

	handleChange(event) {    		    
    this.props.onFretClicked(this.props.fretData.number, this.props.noteNumber);    
	}

  handleChangeHover(event) {    
    if(this.props.hoverModeActive && this.props.spaceIsDown){
        this.props.onFretClicked(this.props.fretData.number, this.props.noteNumber);
    }
  }

  	render() {

		  const number = this.props.fretData.number;
      const name = getNoteNameByNumber(this.props.fretData.number);
  		const isSelected = this.props.fretData.isSelected;
  		const isInScale = this.props.fretData.isInScale;
  		const isOutOfScale = this.props.fretData.isOutOfScale;
      const isDisabled = (!isSelected && this.props.restrictedPlayMode);

      const isLastNote = (this.props.lastNote === number);

  		const fretClassList =       
      `fret ` +
      `${isInScale ? ' fret--in-scale' : ''}`+
      `${isOutOfScale ? ' fret--out-scale' : ''}`+
      `${isSelected ? ' fret--selected' : ''}`+
      `${isSelected === "SCALE_SELECTED" ? ' fret--ghost' : ''}`+
      `${isDisabled ? ' is-disabled' : ''}`+
      `${isLastNote ? ' is-current' : ''}`+
      ` fret--${number}`;

    	return (

        <Tooltip
          title={name}
          theme= "scaley-boi"          
          position="top"
          distance="10"
          arrow="true"
          trigger="mouseenter"
          touchHold="true"
          delay="200"
        >
              <div className={fretClassList}
                   onClick={this.handleChange}
                   onMouseEnter={this.handleChangeHover}
                   data-number={number}
                   data-notename={name}
                   >
                    {isSelected ? '×' : ' - '}
              </div>
        </Tooltip>

    	)
    }
}

export default Fret;
