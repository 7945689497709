import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';

import 'react-tippy/dist/tippy.css';

class PlaybackButton extends Component {

  constructor(props) {
    super(props);
    this.selectPlayback = this.selectPlayback.bind(this);
  }

  selectPlayback(event) {
    this.props.clickHandler(this.props.trigger);
  }

	render() {

    const isActive= (this.props.trigger === this.props.playbackMode) || (this.props.trigger === this.props.outputSourceMode);
    const buttonClass = `playback-button${(isActive)?" is-active":""}`;

  	return (

      <Tooltip
          html={this.props.desc}
          theme= "scaley-boi"
          touchHold="true"
          position="top"
          distance="10"
          arrow="true"
          trigger="mouseenter"
          delay="100"
        >

          <button className={buttonClass} onClick={this.selectPlayback}>
              {this.props.title}
          </button>

        </Tooltip>
  	)
  }
}

export default PlaybackButton;
