import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    const hasTagline = this.props.tagline;

    return (
      <header className="header">
        <h1>Scales {hasTagline ? ` - ${hasTagline}` : "!"}</h1>

        <div className="header__strapline">
          <h6>
            <Link to="/about">about</Link>
          </h6>
        </div>
      </header>
    );
  }
}

export default Header;
