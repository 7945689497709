import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResetButton extends Component {

	constructor(props) {
		super(props);
		this.resetAll = this.resetAll.bind(this);
	}

	resetAll(event) {
		this.props.onResetClicked();
	}

	render() {
  	return (
      <button onClick={this.resetAll}  name="reset">{this.props.label}</button>
  	)
  }
}

ResetButton.propTypes = {
   onResetClicked : PropTypes.func.isRequired
}

ResetButton.defaultProps = {
    label: "LOOKS LIKE YOU DIDN'T ADD A LABEL LOL"
}

export default ResetButton;
