import WebMidi from 'webmidi';

let events;
let input;
let output;
let channel = 1;

const unsupported = !(navigator.requestMIDIAccess);


function setEvents(mainEvents){
  events = mainEvents;
}




function setupInputs(inputName){

  if(unsupported) return;

  // console.log("setupInputs  - " + inputName);

  if(input){
    // Clear any old ones -
    input.removeListener();
  }

  input = WebMidi && WebMidi.getInputByName(inputName);

  if(input){

      WebMidi.octaveOffset = -1;

      input.addListener('noteon', "all", function (e) {
        if(events){
            events.onNoteEvent(e.note.number%12, e.note.number);
        }
      });
  }

};

function setupOutputs(outputName){
  if(unsupported) return;
  output = WebMidi.getOutputByName(outputName);
}

function setChannel(channelName){
  if(unsupported) return;

  channel = Number(channelName);
}

function midiNoteOn(noteNumber){
  if(unsupported) return;

  if(output){
    output.playNote(noteNumber, channel, {duration: 333});
  }
}

export default {
  setEvents: setEvents,
  midiNoteOn: midiNoteOn,
  setupInputs: setupInputs,
  setupOutputs: setupOutputs,
  setChannel: setChannel
}